





import { Component, Prop, Vue } from 'vue-property-decorator'
import { IDocument } from '@/interfaces/Interface'

@Component
export default class Label extends Vue {
  @Prop({ default: '' }) private label!: string
}
